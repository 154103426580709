import React from 'react'
import {connect} from 'react-redux';
import OrganizationWorker from './OrganizationWorker';
import {toggleOrganizationListFilter,defaultPage} from './OrganizationList-Actions'
import store from '../../../Store';
import TableHead from "../../../components/shared/table/TableHead";
import {Link} from "react-router-dom";
import swal from "sweetalert";
import deleteOrganization from "../../../ajax/actions/organization/organization_delete";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import Page from "../../../components/shared/pages/Page";
import OrganizationListFilters from "./OrganizationListFilters";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Loading from "../../../components/shared/loaders/Loading";
import ResponsiveModalForm from "../../../components/shared/modals/ResponsiveModalForm";
import Enable2Fa from "../../two-factor-authentication/Enable2Fa";

class Payment extends React.Component {
    state={
        noSites:false,
    }

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    onClickNoSiteOrganization = () =>{
        this.state.noSites?
        this.setState({noSites: false}):
        this.setState({noSites: true});
        store.dispatch(defaultPage());
    }

    toggleSort = (field) => {
        store.dispatch(toggleOrganizationListFilter({
            target: {name: `sort_by`, value: field}
        }))
    };

    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this organization? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id) {
        store.dispatch(deleteOrganization(id));
    }

    onclick(id) {

        this.props.history.push(`/organizations/${id}/sites`)
    }

    render() {
        let {pages, getOrganizations, params, fields, organizations, role, twoFa} = this.props;
        let {processing, error} = getOrganizations;
        if (error) {
            this.props.history.push('/');

        }
        return (
            <Page title="Organizations" processing={processing}>
               {/* <ResponsiveModalForm visible={!twoFa}
                                     closeIcon={false}
                                     closeOnEsc={false}
                                     closeOnOverlayClick={false}
                                     twoFactorauthenticationPopup={true}
                >
                    <Enable2Fa/>
                </ResponsiveModalForm>*/}
                <OrganizationListFilters title="Organization" processing={processing} noSites={this.state.noSites} siteClick={this.onClickNoSiteOrganization} role={role}/>

                <div className="table-block table-responsive pr-60 table-list-block">
                    <table className="table table-striped ">
                        <TableHead fields={fields}
                                   params={params}
                                   noActions={true}
                                   onClick={this.toggleSort}
                                   processing={processing}
                        />

                        <tbody>
                        {processing ? <td colSpan={5} className="text-center"><Loading/></td> :
                            organizations && organizations.length ? organizations.map((organization, i) => {
                                return (
                                    // <tr key={i} className='clickable-row' data-href="javascript:void(0)" onClick={() => this.onclick( organization.id )}>
                                 this.state.noSites && organization.sites_count === 0 ?
                                    <tr key={i}>

                                        <td className="onclick"
                                            onClick={() => this.onclick(organization.id)}>{organization.sites_count}</td>
                                        <td className="onclick" onClick={() => this.onclick(organization.id)}>
                                            {organization.name}
                                        </td>
                                        <td className="onclick"
                                            onClick={() => this.onclick(organization.id)}>{organization.customers[0] ?
                                            <>{organization.customers[0]['first_name']} {organization.customers[0]['last_name']}</>: `-`}</td>
                                        <td className="onclick"
                                            onClick={() => this.onclick(organization.id)}>{organization.city}</td>
                                        <td className="onclick">
                                            {
                                                organization.billable === 1 ?
                                                "Active"
                                                :
                                                "Inactive"
                                            }
                                        </td>
                                        <td>
                                            <div className="dropdown dropdown-alt text-center">
                                                <span className="dropdown-toggle"
                                                      data-toggle="dropdown">
                                                    <span className="icon-more"/>
                                                </span>
                                                <div className="dropdown-menu">
                                                    <Link to={`organizations/${organization.id}/update`}
                                                          className={`link-edit`}>
                                                        Edit
                                                    </Link>
                                                    <a href="/#"
                                                       onClick={ev => {this.deleteConfirm(organization.id);ev.preventDefault()}}
                                                       className={`link-delete`}>
                                                        Delete
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>:
                                     !this.state.noSites &&
                                     <tr key={i}>

                                         <td className="onclick"
                                             onClick={() => this.onclick(organization.id)}>{organization.sites_count}</td>
                                         <td className="onclick" onClick={() => this.onclick(organization.id)}>
                                             {organization.name}
                                         </td>
                                         <td className="onclick"
                                             onClick={() => this.onclick(organization.id)}>{organization.customers[0] ?
                                             <>{organization.customers[0]['first_name']} {organization.customers[0]['last_name']}</>: `-`}</td>
                                         <td className="onclick"
                                             onClick={() => this.onclick(organization.id)}>{organization.city}</td>
                                        <td className="onclick">
                                            {
                                                organization.billable === 1 ?
                                                "Active"
                                                :
                                                "Inactive"
                                            }
                                        </td>

                                         <td>
                                             <div className="dropdown dropdown-alt text-center">
                                                <span className="dropdown-toggle"
                                                      data-toggle="dropdown">
                                                    <span className="icon-more"/>
                                                </span>
                                                 <div className="dropdown-menu">
                                                     <Link to={`organizations/${organization.id}/update`}
                                                           className={`link-edit`}>
                                                         Edit
                                                     </Link>
                                                     <a href="/#"
                                                        onClick={ev => {this.deleteConfirm(organization.id);ev.preventDefault()}}
                                                        className={`link-delete`}>
                                                         Delete
                                                     </a>
                                                 </div>
                                             </div>
                                         </td>
                                     </tr>
                                )
                            }) : <tr>
                                <td colSpan="5" className="text-center">No Organizations Available</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                {organizations && organizations.length ? (
                    <>

                    <div className="fixed-footer pr-60">
                        <div className="footer-text-block">
                            {/* <span>
                                <a href="#" onClick={this.onClickNoSiteOrganization} >
                                    {this.state.noSites? "All Organizations" :"Organisation having 0 sites"}
                                </a>
                            </span> */}
                        </div>
                        <PaginationButtons pages={pages}
                                           processing={processing}
                                           fetchAction={toggleOrganizationListFilter}/>
                    </div>
                    </>
                ) : null}
                <OrganizationWorker/>
            </Page>
        )
    }
}

function mapStateToProps(state) {
    let {getOrganizations, userDetail, organizationList, route, getLoggedInUser} = state;

    let twoFa = getLoggedInUser
                && getLoggedInUser.success
                && getLoggedInUser.success.data
                && getLoggedInUser.success.data.data.twoFa

    let {params, fields} = organizationList.data;

    let organizations =
        getOrganizations &&
        getOrganizations.success &&
        getOrganizations.success.data &&
        getOrganizations.success.data.data &&
        getOrganizations.success.data.data.data;

    let current_page = getOrganizations.success && getOrganizations.success.data && getOrganizations.success.data.data.current_page;
    let last_page = getOrganizations.success && getOrganizations.success.data && getOrganizations.success.data.data.last_page;
    let total = getOrganizations.success && getOrganizations.success.data && getOrganizations.success.data.data.total;
    let pages = {current_page, last_page, total};
    let role = route && route.data.user && route.data.user.role.slug;

    return {userDetail, organizationList, params, fields, getOrganizations, organizations, pages, role, twoFa};
}

export default connect(mapStateToProps)(Payment);