import React from 'react'
import {connect} from 'react-redux';
import SiteWorker from './SiteWorker';
import { toggleSiteListFilter} from './SiteList-Actions'
import store from '../../../Store';
import TableHead from "../../../components/shared/table/TableHead";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Page from "../../../components/shared/pages/Page";
import SiteListFilters from "./SiteListFilters";
import SiteTableCell from "../../super-admin-sites/list/SiteTableCell";
import Loading from "../../../components/shared/loaders/Loading";

class Site extends React.Component {

    state = {
        initialLoading : false
    }

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
            store.dispatch(  setDashboardMenu(''));
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    componentDidUpdate(prevProps) {
        if( this.props.getSuperAdminSites !== prevProps.getSuperAdminSites ) {
            let { processing, success } = this.props.getSuperAdminSites;
            if( processing ) {
                this.setState({ initialLoading:true });
            }

            if( success ) {
                this.setState({ initialLoading: false })
            }
        }
    }

    toggleSort = (field) => {
        store.dispatch(toggleSiteListFilter({
            target: {name: `sort_by`, value: field}
        }))
    };

    onclick(id,user_id)
    {

        this.props.history.push(`/organizations/${id}/sites/${user_id}/launchpad/admin`)
    }




    render() {
        let { pages, getSuperAdminSites, list, params, fields } = this.props;
        let { processing, error } = getSuperAdminSites;
        if (error)
        {
            this.props.history.push('/');
        }

        return (
            <>
                <Page title="Sites" processing={processing}>
                    <SiteListFilters title="sites"/>
                    <div className="table-block table-responsive pr-60">
                        <table className="table table-striped  ">
                            <TableHead fields={fields}
                                       params={params}
                                       noActions={true}
                                       onClick={this.toggleSort}/>
                            <tbody>
                            { this.state.initialLoading  ? <td colSpan={3} className="text-center"><Loading/></td> :
                                <SiteTableCell />
                            }
                            </tbody>
                        </table>
                        { list && list.length ? (
                            <div className="fixed-footer pr-60">
                                <PaginationButtons pages={pages}
                                                   processing={ processing }
                                                   fetchAction={toggleSiteListFilter} />
                            </div>
                        ) : null }
                    </div>
                    <SiteWorker />
                </Page>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {  route , superAdminSiteList, getSuperAdminSites, getServerModules , getPermissions , getAdminSiteStatus  } = state;
    let { params, fields, modal } = superAdminSiteList.data;

    let {success} = getSuperAdminSites;
    let list =
        success &&
        success.data &&
        success.data.data &&
        success.data.data.data;

    let current_page = success &&  success.data && success.data.data.current_page;
    let last_page = success && success.data && success.data.data.last_page;
    let total = success && success.data && success.data.data.total;
    let pages = { current_page, last_page, total };

    return {getSuperAdminSites, list, route, superAdminSiteList, params, fields, modal, pages, getServerModules , getPermissions , getAdminSiteStatus };
}
export default connect(mapStateToProps)(Site);