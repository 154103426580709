import React from "react";
import { Link } from "react-router-dom";

class HeaderNavigation extends React.Component {
  render() {
    let { url, role, startOfURL } = this.props;

    return (
      <header className="top-navigation">
        {role === "organization-admin" ? (
          ""
        ) : (
          <ul>
            <li>
              <Link
                className={
                  startOfURL === "organizations" ||
                  startOfURL === "sites" ||
                  (startOfURL === "activitylog" && url === "organizations")
                    ? "active"
                    : ""
                }
                to="/organizations"
              >
                Organizations
              </Link>
            </li>
            <li>
              <Link
                className={
                  startOfURL === "data-center" ||
                  startOfURL === "server" ||
                  startOfURL === "load-balancer" ||
                  (startOfURL === "activitylog" && url === "infrastructure")
                    ? "active"
                    : ""
                }
                to="/data-center"
              >
                Infrastructure
              </Link>
            </li>
            <li>
              <Link
                className={
                  startOfURL === "allusers" ||
                  startOfURL === "roles" ||
                  startOfURL === "role"
                    ? "active"
                    : ""
                }
                to="/allusers"
              >
                Users
              </Link>
            </li>
            <li>
              <Link
                className={
                  startOfURL === "price" ||
                  startOfURL === "discount" ||
                  startOfURL === "coupon"
                }
                to="/price"
              >
                Billing
              </Link>
            </li>
            <li>
              <Link
                className={startOfURL === "internal-support" ? "active" : ""}
                to="/internal-support"
              >
                Support
              </Link>
            </li>
          </ul>
        )}
      </header>
    );
  }
}

export default HeaderNavigation;
